import React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { Button, Flex, Form, DatePicker, Row, Col, Card, Typography, Divider, message } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'

import {
  arrayMove,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'

import { InputState } from '../../types/form'
import { useAPIContext } from '../context/api'
import { CarouselAPI } from '../../../constructs/api/carousel/interface'
import { CalendarAPI } from '../../../constructs/api/calendar/interface'
import { SortableCard } from '../components/sortable-card'
import { CalendarContent } from '../../../types/calendar'
import { validateUrl } from '../utils/url-validator'

const { Paragraph, Link, Text } = Typography
const today = dayjs()

const MAX_INPUTS = 5
const REQUIRED_INPUTS = 4

const initialInputState: InputState = {
  id: '',
  order: 0,
  url: '',
  cta: '',
  title: '',
  showPlaceholder: true,
  isValid: false,
  ctaUpdated: false,
  titleUpdated: false,
}

const generateInitialInputs = (length: number): InputState[] =>
  Array.from({ length }, (_, index) => ({
    ...initialInputState,
    id: uuidv4(),
    order: index + 1,
  }))

export const EditFormPage: React.FC = () => {
  const { callEndpoint } = useAPIContext()

  const [form] = Form.useForm()
  const date = Form.useWatch('date', form)
  const currentDate = date?.format('YYYY-MM-DD') || today.format('YYYY-MM-DD')

  const [datesWithContent, setDatesWithContent] = React.useState<string[]>([])

  const [isFormComplete, setIsFormComplete] = React.useState(false)
  const [showReminder, setShowReminder] = React.useState(true)

  const [inputs, setInputs] = React.useState<InputState[]>(() => generateInitialInputs(MAX_INPUTS))
  const inputsDisplayed = inputs.slice(0, MAX_INPUTS)

  const [isModified, setIsModified] = React.useState(false)

  const [sentSuccessByDate, setSentSuccessByDate] = React.useState<Record<string, boolean[]>>({})

  // Get the success state for the current date, or sets it to false
  const sentSuccess = sentSuccessByDate[currentDate] || Array(MAX_INPUTS).fill(false)

  // Sensors for drag and drop functionality
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  // Function to handle the drag and drop of the inputs
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setInputs(inputs => {
        const oldIndex = inputs.findIndex(input => input.id === active.id)
        const newIndex = inputs.findIndex(input => input.id === over?.id)
        const reorderedInputs = arrayMove(inputs, oldIndex, newIndex)

        return reorderedInputs.map((input, index) => ({
          ...input,
          order: index + 1,
        }))
      })
    }
  }

  const fetchCarouselData = React.useCallback(
    (selectedDate: dayjs.Dayjs) => {
      callEndpoint<CarouselAPI, 'get'>('carousel', `/${selectedDate.format('YYYY-MM-DD')}`, 'GET')
        .then(carousel => {
          const content = carousel.content || []
          const formValues: Record<string, string> = {}

          const updatedInputs = Array.from({ length: MAX_INPUTS }, (_, index) => {
            const carouselItem = content[index] || {}

            formValues[`link${index}`] = carouselItem.link || ''
            formValues[`cta${index}`] = carouselItem.cta || ''
            formValues[`title${index}`] = carouselItem.title || ''

            return {
              id: inputs[index].id,
              order: inputs[index].order,
              url: carouselItem.link || '',
              cta: carouselItem.cta || '',
              title: carouselItem.title || '',
              showPlaceholder: !carouselItem.link,
              isValid: !!carouselItem.link,
              ctaUpdated: !!carouselItem.cta,
              titleUpdated: !!carouselItem.title,
            }
          })

          // Sets a success state for each input
          setSentSuccessByDate(prev => ({
            ...prev,
            [selectedDate.format('YYYY-MM-DD')]: updatedInputs.map(input => input.isValid),
          }))

          form.setFieldsValue(formValues)
          setInputs(updatedInputs)
          setIsModified(false) // Resets `isModified` after fetching the data
        })
        .catch(err => {
          const errorMessage = err?.message || ''
          if (errorMessage.includes('404')) {
            const emptyInputs = generateInitialInputs(MAX_INPUTS)
            setInputs(emptyInputs)
          }
        })
    },
    [callEndpoint, form],
  )

  const fetchCarouselDates = React.useCallback(() => {
    callEndpoint<CalendarAPI, 'get'>('calendar', '', 'GET')
      .then(calendar => {
        const content = calendar || []
        const calendarDates: string[] = content.map((calendarDateItem: CalendarContent) => {
          return calendarDateItem.date
        })
        setDatesWithContent(calendarDates)

        return calendarDates
      })
      .catch(err => {
        console.error('Error fetching calendar dates:', err)
        if (err?.message?.includes('404')) {
          return []
        }
      })
    return []
  }, [callEndpoint])

  // Function to handle the form submission
  const handleSubmit = async (values: any) => {
    const date = values.date.format('YYYY-MM-DD')

    const carousel = {
      content: inputs
        .filter(input => values[`link${input.id}`]?.trim())
        .map(input => ({
          id: input.id,
          order: input.order,
          link: values[`link${input.id}`],
          cta: values[`cta${input.id}`],
          title: values[`title${input.id}`],
        })),
    }

    try {
      const res = await callEndpoint<CarouselAPI, 'save'>('carousel', `/${date}`, 'POST', carousel)

      if (!res) throw new Error('Respuesta del servidor inesperada')

      message.success('El contenido se ha enviado exitosamente')

      setIsModified(false)

      // Update the success state for the inputs that were sent
      setSentSuccessByDate(prev => ({
        ...prev,
        [date]: inputs.map(
          (_, index) =>
            !!values[`link${index}`]?.trim() &&
            !!values[`cta${index}`]?.trim() &&
            !!values[`title${index}`]?.trim(),
        ),
      }))

      setShowReminder(false)
      fetchCarouselDates()
    } catch (error: any) {
      message.error('Hubo un error al enviar el contenido. Intenta nuevamente')
    }
  }

  // Function to handle each input change on the form fields
  const handleInputChange = (id: string, field: 'url' | 'cta' | 'title', value: string) => {
    setInputs(prevInputs => {
      return prevInputs.map(input => {
        if (input.id === id) {
          // Mark the input as modified if the value changes
          if (input[field] !== value) setIsModified(true)

          // Reset success state when the input changes
          const inputIndex = prevInputs.findIndex(input => input.id === id)

          setSentSuccessByDate(prev => {
            const currentDate = date?.format('YYYY-MM-DD') || today.format('YYYY-MM-DD')
            const newSentSuccess = [...(prev[currentDate] || [])]

            if (inputIndex !== -1) {
              newSentSuccess[inputIndex] = false
            }

            return { ...prev, [currentDate]: newSentSuccess }
          })

          // Update the input state
          const updates: Partial<InputState> = { [field]: value }
          if (field === 'url') {
            updates.showPlaceholder = !value
            updates.isValid = validateUrl(value)

            if (!value) {
              updates.title = ''
            }
            updates.cta = value
            updates.title = ''
          } else if (field === 'cta') {
            updates.cta = value
            updates.isValid = validateUrl(value)
            updates.ctaUpdated = true
          } else if (field === 'title') {
            updates.title = value.replace(/\n+/g, ' ')
            updates.titleUpdated = true
          }

          return { ...input, ...updates }
        }
        return input
      })
    })
  }

  // Effect to initialize the form values with the carousel data
  React.useEffect(() => {
    const formValues: Record<string, string> = {}
    inputs.forEach(input => {
      formValues[`link${input.id}`] = input.url
      formValues[`cta${input.id}`] = input.cta
      formValues[`title${input.id}`] = input.title
    })
    form.setFieldsValue(formValues)
  }, [inputs, form])

  // Effect to fetch the carousel data when the date changes
  React.useEffect(() => {
    if (date) {
      fetchCarouselData(date)
    }
  }, [date, fetchCarouselData])

  // Effect to fetch the calendar dates
  React.useEffect(() => {
    fetchCarouselDates()
  }, [fetchCarouselDates])

  // Effect to check if the form is complete
  React.useEffect(() => {
    const allUrlsFilled = inputs
      .slice(0, REQUIRED_INPUTS)
      .every(
        input => input.url.trim() !== '' && input.cta.trim() !== '' && input.title.trim() !== '',
      )
    setIsFormComplete(allUrlsFilled && !!date)
  }, [inputs, date])

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Flex style={{ height: '100%', overflowX: 'hidden' }}>
        <Row gutter={[48, 24]} style={{ width: '100%', paddingTop: '2em' }}>
          <Col xs={{ span: 24, offset: 1 }} lg={{ span: 10, offset: 1 }}>
            <Card style={{ textAlign: 'left', width: '100%' }}>
              <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                👋 Bienvenido al content management
              </Typography.Title>
              <Paragraph>
                Cargá los contenidos de tus publicaciones siguiendo estos pasos:
              </Paragraph>
              <Card
                style={{
                  border: 'none',
                  backgroundColor: '#F9FAFF',
                  borderRadius: '8px',
                }}
              >
                <Paragraph>
                  1. Selecciona la fecha en la que quieras cargar el contenido del widget
                </Paragraph>
                <Paragraph>2. Copia y pega el link del reel</Paragraph>
                <Paragraph>3. Agrega un título</Paragraph>
                <Paragraph>
                  4. Si queres, podes mover las cards para elegir el orden de los videos
                </Paragraph>
                <Paragraph>
                  5. Hace click en <strong>enviar</strong> y listo! tus contenidos quedan
                  programados.
                </Paragraph>
              </Card>
              <Divider />
              <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                Si tenés dudas o algún problema, envianos un email a:
                <Paragraph style={{ marginBottom: 0 }}>
                  <Link style={{ fontSize: '18px' }}>soporte@drupi.io</Link>
                </Paragraph>
              </Typography.Title>
            </Card>
          </Col>

          <Col xs={{ span: 24, offset: 1 }} lg={11}>
            <Form layout='vertical' form={form} onFinish={handleSubmit} requiredMark={false}>
              <Flex
                justify='space-between'
                style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}
              >
                <div>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Carga tus contenidos
                  </Typography.Title>
                </div>

                <div>
                  <Form.Item
                    name='date'
                    rules={[{ type: 'object', required: true, message: 'Seleccionar una fecha' }]}
                    style={{ marginBottom: 0, flexGrow: 1, width: '100%' }}
                    initialValue={today}
                  >
                    <DatePicker
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        padding: '8px 16px',
                      }}
                      className='custom-placeholder'
                      placeholder={'Seleccionar fecha'}
                      suffixIcon={<CalendarOutlined style={{ color: '#344054' }} />}
                      cellRender={currentDate => {
                        const isDayjs =
                          currentDate && typeof currentDate === 'object' && 'format' in currentDate
                        const isDateWithContent =
                          isDayjs && datesWithContent.includes(currentDate.format('YYYY-MM-DD'))
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: isDateWithContent ? '#FFFFFF' : undefined,
                              borderRadius: '50%',
                              width: '32px',
                              height: '32px',
                              backgroundColor: isDateWithContent ? '#8a8aff' : undefined,
                            }}
                          >
                            {isDayjs ? currentDate.date() : currentDate}
                          </div>
                        )
                      }}
                    />
                  </Form.Item>
                </div>
              </Flex>

              {/* Once loaded, render the sortable cards within the SortableContext for drag-and-drop functionality. */}
              <SortableContext items={inputs.map(input => input.id)} strategy={rectSortingStrategy}>
                {inputsDisplayed.map((input, index) => (
                  <SortableCard
                    key={input.id}
                    input={input}
                    required={input.order <= REQUIRED_INPUTS}
                    handleInputChange={handleInputChange}
                    isSuccess={sentSuccess[index]}
                  />
                ))}
              </SortableContext>

              {/* Submit button and reminder */}
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {showReminder && isFormComplete && (
                      <Text
                        style={{
                          fontWeight: 'bold',
                          fontSize: '16px',
                          color: '#3E5569',
                        }}
                      >
                        Recorda enviar el contenido :)
                      </Text>
                    )}
                  </div>
                  <div>
                    <Button
                      type='primary'
                      style={{ width: '195px', height: '54px', border: 'none' }}
                      htmlType='submit'
                      disabled={!isFormComplete || !isModified}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Flex>
    </DndContext>
  )
}
