import React, { CSSProperties } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { MenuOutlined } from '@ant-design/icons'
import { CardContent } from './card-content'
import { CardProps } from '../../types/card'

/*
 * useSortable: hook that is used to make an element draggable
 * setNodeRef: function that is used to reference the DOM node of the element that will be draggable
 * listeners:  object that contains the drag events
 * attributes: object that contains the necessary attributes for the element to be draggable
 */

export const SortableCard: React.FC<CardProps> = ({
  input,
  required,
  handleInputChange,
  isSuccess,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: input.id,
    disabled: false,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 'auto',
    position: 'relative',
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Separated div with drag attributes */}
        <div
          {...attributes}
          {...listeners}
          style={{
            cursor: 'move',
            display: 'flex',
            width: 'auto',
            height: '196.8px',
            backgroundColor: '#EAECF5',
            borderTopLeftRadius: '35px',
            borderBottomLeftRadius: '35px',
            marginBottom: '10px',
            padding: '7px',
          }}
        >
          <MenuOutlined style={{ color: '#363F72' }} />
        </div>
        <div style={{ flex: 1 }}>
          <CardContent
            input={input}
            required={required}
            handleInputChange={handleInputChange}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    </div>
  )
}
