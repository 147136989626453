const VALID_URL = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/

/**
 * Validates if a URL has the correct format.
 * @param url The URL to validate
 * @returns true if the URL is valid, false otherwise
 */
export const validateUrl = (url: string): boolean => {
  return VALID_URL.test(url)
}
