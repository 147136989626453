import React from 'react'
import { useAPIContext } from '../context/api'
import { InstagramAPI } from '../../../constructs/api/instagram/interface'

import { CardProps } from '../../types/card'

import { InstagramPreview } from './instagram-preview'
import { sanitizeUrl } from '../utils/url-sanitizer'
import { validateUrl } from '../utils/url-validator'

import { Card, Form, Input, Typography, Tooltip } from 'antd'
import { LinkOutlined, CheckCircleOutlined } from '@ant-design/icons'
const { Text } = Typography

const PREVIEW_WIDTH = 72
const PREVIEW_HEIGHT = 128
const VALID_IG_LINK = /https:\/\/www\.instagram.com\/reel\/\S{9,}\/?/

export const CardContent: React.FC<CardProps> = React.memo(
  ({ input, required, handleInputChange, isSuccess }) => {
    const { callEndpoint } = useAPIContext()
    const [reelState, setReelState] = React.useState({
      title: '',
      previewUrl: '',
      loading: false,
    })
    const isLoaded = !!input.url && reelState.previewUrl

    const fetchReelData = React.useCallback(
      async (reelUrl: string) => {
        // If the URL is not valid, clear the reel state
        if (!VALID_IG_LINK.test(reelUrl)) {
          setReelState({ title: '', previewUrl: '', loading: false })
          return
        }

        // Set the loading state to true if the URL is valid
        setReelState(prev => ({ ...prev, loading: true }))

        try {
          const sanitizedReelUrl = sanitizeUrl(reelUrl)

          const response = await callEndpoint<InstagramAPI, 'get-preview'>(
            'instagram',
            '',
            'POST',
            {
              reelUrl: sanitizedReelUrl,
            },
          )

          if (response) {
            setReelState({
              title: response.title || '',
              previewUrl: response.previewUrl,
              loading: false,
            })

            // If the title is empty, set it to the default reel title
            if (!input.title) {
              handleInputChange(input.id, 'title', response.title)
            }
          }
        } catch (err) {
          console.error('Error fetching reel data:', err)
        } finally {
          setReelState(prev => ({ ...prev, loading: false }))
        }
      },
      [callEndpoint, handleInputChange, input.id, input.title],
    )

    // Clears the CTA field if it matches the URL when focused
    const handleFocusCTA = React.useCallback(() => {
      if (input.cta === input.url) {
        handleInputChange(input.id, 'cta', '')
      }
    }, [input.cta, input.url, handleInputChange, input.id])

    // Sets the CTA field to the URL if it is empty when blurred
    const handleBlurCTA = React.useCallback(() => {
      if (input.cta.trim() === '') {
        handleInputChange(input.id, 'cta', input.url)
      }
    }, [input.cta, input.url, handleInputChange, input.id])

    // Sets the title field to the default reel title if it is empty when blurred
    const handleBlurTitle = React.useCallback(() => {
      if (input.title.trim() === '') {
        handleInputChange(input.id, 'title', reelState.title)
      }
    }, [input.title, reelState.title, handleInputChange, input.id])

    // Fetches the reel data when the URL is valid and the input is valid
    React.useEffect(() => {
      if (input.url && input.isValid) fetchReelData(input.url)
    }, [input.url, input.isValid, fetchReelData])

    return (
      <Card
        key={input.id}
        style={{
          marginBottom: '10px',
          padding: '0px 8px',
          height: 'auto',
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
        }}
        data-no-dnd='true'
      >
        <Text
          style={{
            display: 'block',
            fontWeight: 'bold',
            fontSize: '13px',
            color: '#3E5569',
          }}
        >
          {`Contenido ${input.order} ${required ? '*' : '(opcional)'}`}
          {/* Ícono de validación al lado del título */}
          {isSuccess && (
            <Tooltip title='Contenido guardado'>
              <CheckCircleOutlined
                style={{ color: '#119400', marginLeft: '8px', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Text>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
          data-no-dnd='true'
        >
          <div
            style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}
            data-no-dnd='true'
          >
            <Form.Item
              name={`link${input.id}`}
              rules={[
                {
                  required: required,
                  message: 'Ingresar una URL válida',
                },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                value={input.url}
                onChange={e => handleInputChange(input.id, 'url', e.target.value)}
                prefix={<LinkOutlined />}
                placeholder='Pega tu URL aquí'
                style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
              />
            </Form.Item>

            <Form.Item
              name={`cta${input.id}`}
              rules={
                !required // 5th card is optional
                  ? [
                      {
                        validator: (_, value) =>
                          value && !validateUrl(value) // Validar solo si hay un valor
                            ? Promise.reject(new Error('El CTA debe ser una URL válida.'))
                            : Promise.resolve(),
                      },
                    ]
                  : [
                      { required: true, message: 'Por favor, ingresa un CTA válido.' },
                      {
                        validator: (_, value) =>
                          value && !validateUrl(value)
                            ? Promise.reject(new Error('El CTA debe ser una URL válida.'))
                            : Promise.resolve(),
                      },
                    ]
              }
              style={{ margin: '0px' }}
            >
              <Input
                value={input.cta}
                onChange={e => handleInputChange(input.id, 'cta', e.target.value)}
                onFocus={handleFocusCTA}
                onBlur={handleBlurCTA}
                placeholder='Ingresa el enlace del CTA'
                style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
              />
            </Form.Item>

            <Form.Item
              name={`title${input.id}`}
              rules={[
                {
                  required: required,
                  message: 'Ingresar un título válido',
                },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                data-no-dnd='true'
                value={input.title || reelState.title}
                onChange={e => handleInputChange(input.id, 'title', e.target.value)}
                onBlur={handleBlurTitle}
                placeholder='Título del reel'
                style={{ color: isLoaded ? '#A7A7A7' : 'inherit' }}
              />
            </Form.Item>
          </div>

          {input.showPlaceholder ? (
            <div
              style={{
                width: PREVIEW_WIDTH,
                height: PREVIEW_HEIGHT,
                backgroundColor: '#F8F7F7',
                borderRadius: 3,
              }}
              data-no-dnd='true'
            ></div>
          ) : (
            <InstagramPreview
              reelUrl={input.url}
              previewUrl={reelState.previewUrl}
              loading={reelState.loading}
            />
          )}
        </div>
      </Card>
    )
  },
  (prevProps, nextProps) =>
    prevProps.input.url === nextProps.input.url &&
    prevProps.input.id === nextProps.input.id &&
    prevProps.input.title === nextProps.input.title &&
    prevProps.input.order === nextProps.input.order,
)
